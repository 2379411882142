import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "_metronic/_helpers";

export function CompanyHeader() {
  return(
    <>
      <div className="header-menu-wrapper header-menu-wrapper-center" id="kt_header_menu_wrapper">
        <div className="header-logo" style={{display:'flex', height:'100%', alignItems:'center'}}>
          <Link to="/" title="StakeCare">
            <img style={{height:40}} alt="logo" src={toAbsoluteUrl(`/media/logos/transparent-icon.svg`)}/>
          </Link>
        </div>
      </div>
    </>
  )
}
