import React from "react";
import { injectIntl } from "react-intl"
import { PositionType, PositionTypes } from "app/models/position";
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import { convertMillisToMinutes } from "_metronic/_helpers";

export interface PHistoryData {
  timestamp: number
  positionId: PositionTypes
}
export interface PHistoryProps {
  intl: any
  data: Array<PHistoryData>
  width?: number
  height?: number
}
function PositionDurationSumBarChart(props: PHistoryProps) {
  const { intl, data, width, height } = props

  const [positionTypeList] = React.useState<Array<string>>([
    PositionType.DV,
    PositionType.DLE_DV,
    PositionType.DLE,
    PositionType.DLD_DV,
    PositionType.DDH,
    PositionType.DDH_DLE,
    PositionType.DLD,
    PositionType.DDH_DLD,
    PositionType.DDE,
    PositionType.DDH_DDE,
    PositionType.SED,
    PositionType.DDE_SED,
    PositionType.FALL_RAISED,
    PositionType.INV,
    PositionType.UPSIDE_DOWN,
    PositionType.UNK
  ])
  const [positionTypesMappingTextToId] = React.useState<Map<string, number>>(positionTypeList.reduce((p: Map<string, number>, v: string, i: number) => { p.set(v, i); return p }, new Map() as Map<string, number>))

  const [options] = React.useState<ApexOptions>({
    chart: {
      id: 'position-duration-sum',
      toolbar: {
        show: false,
        tools: {
          download: false
        }
      }
    },
    title: {
      text: intl.formatMessage({id: 'CHART.PACIENT_HISTORY.POSITION.TOTAL_DURATION.TITLE'}),
      align: 'left'
    },
    noData: {
      text: intl.formatMessage({id: 'CHART.PACIENT_HISTORY.POSITION.NO_DATA'}),
    },
    markers: {
      size: 6,
      showNullDataPoints: true,
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      followCursor: false,
      x: {
        show: false,
        formatter: (ts: number) => `x${ts}`
      },
      y: {
        title: {
          formatter: (seriesName: string) => ""
        },
        formatter: (duration: number, opts?: any) => {
          const posId = positionTypeList[opts?.dataPointIndex]
          const translation = intl.formatMessage({id: `POSITIONS.${posId}.SHORT`})
          const minDuration = convertMillisToMinutes(duration).toFixed(0)
          
          return intl.formatMessage(
            { id: "CHART.PACIENT_HISTORY.POSITION.TOTAL_DURATION.Y.TOOLTIP"},
            { duration: minDuration, position: translation }
          )
        }
      }
    },
    xaxis: {
      type: 'category',
      categories: positionTypeList,
      tickAmount: positionTypeList.length,
      tickPlacement: 'on',
      labels: {
        rotate: -45,
        rotateAlways: true
      }
    },  
    yaxis: {
      labels: {
        formatter: (minutes: number, opts?: any) => `${convertMillisToMinutes(minutes).toFixed(0)}min`,
        maxWidth: 40,
        minWidth: 40,
        style: {
          fontSize: '10px'
        }
      }
    }
  })
  const [dataSerie, setDataSerie] = React.useState<Array<number>>([])

  React.useEffect(() => {
    if (data.length === 0) return

    const initializedData = positionTypeList.map((val) => 0)
    const sanitizedDataSerie = data.reduce((dataArray: Array<number>, currentValue: PHistoryData, index: number) => {
      // if last item, return
      if (index === data.length) return dataArray

      const currentId = positionTypesMappingTextToId.get(currentValue.positionId)

      if (currentId === undefined) return dataArray

      const currentTs = currentValue.timestamp
      const nextTs = data[index+1]?.timestamp

      const duration = nextTs - currentTs
      const currentSum = dataArray[currentId] ?? 0

      dataArray[currentId] = currentSum + duration
      return dataArray
    }, initializedData)

    setDataSerie(sanitizedDataSerie)
  }, [data, positionTypeList, positionTypesMappingTextToId])

  return (
    <Chart 
      type="bar"
      options={options} 
      series={[
        {
          name: 'permanencia',
          data: dataSerie
        }
      ]} 
      width={width ?? '100%'} 
      height={height ?? '100%'} 
      />
  )
}
export default injectIntl((PositionDurationSumBarChart))
