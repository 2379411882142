import React from "react";
import { get as _get } from "lodash"
import { NewFormTextField } from "app/modules/Globals";
import { convertStringBrDateToAmerican, getAge } from "_metronic/_helpers";

export const FormBirthdayField = (props: any) => {
  const { displayAge, values, valueRef } = props
  
  const value = _get(values, valueRef) ?? ""  
  const appendText = (displayAge && value?.length === 10) ? `${ getAge(new Date(convertStringBrDateToAmerican(value))) } anos` : undefined 

  return (
    <>
      <NewFormTextField {...props} withMask="99/99/9999" withAppendText={appendText} />
    </>
  )
}
