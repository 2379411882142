/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem } from "."
import { injectIntl } from "react-intl";
import {
  homeBasePath
} from "api/endpoints"

function PrincipalSection(props) {
  const { intl } = props
  const cProps = {
    intl: intl,
    display: true
  }

  return (
      <>
        <MenuItem 
          {...cProps}
          title="Home"
          url={homeBasePath}
          icon="/media/svg/icons/Design/Layers.svg"
          />    
      </>
  );
}

export default injectIntl(PrincipalSection)