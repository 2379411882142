import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "api/authenticate";
import { getTenantStorageReduceKey } from "tenants/tenant";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  RefreshSectors: "[Set Sectors] Action",
  RefreshLastSync: "[Set LastSync] Action"
};

const initialAuthState = {
  user: undefined,
  sectors: [],
  authToken: undefined,
  lastSync: undefined
};

export const reducer = persistReducer(
  { storage, key: getTenantStorageReduceKey(), whitelist: ["user", "sectors", "lastSync", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user, sectors, lastSync } = action.payload;
        return { ...state, user, sectors, lastSync };
      }

      case actionTypes.RefreshSectors: {
        const { sectors } = action.payload;
        return { ...state, sectors};
      }

      case actionTypes.RefreshLastSync: {
        const { lastSync } = action.payload
        return { ...state, lastSync }
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestSession: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillSession: ({user, sectors, lastSync}) => ({ 
    type: actionTypes.UserLoaded, 
    payload: { user, sectors, lastSync }
  }),
  refreshSectors: (sectors) => ({type: actionTypes.RefreshSectors, payload: { sectors } }),
  refreshLasySync: (lastSync) => ({type: actionTypes.RefreshLastSync, payload: { lastSync } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestSession());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestSession());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const sessionProps = yield getUserByToken();
    yield put(actions.fulfillSession(sessionProps));
  });
}
