/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { MenuItem } from "."
import { monitoringSectorPath } from "api/endpoints"
import { generatePath } from "react-router-dom";

function MonitoringSection(props) {
  const { intl, sectors } = props
  const cProps = {
    intl: intl,
    display: true
  }

  if (!sectors || sectors.length === 0) return null

  return (
      <>
        <li className="menu-section ">
          <h4 className="menu-text"><FormattedMessage id="MENU.MONITORING" /></h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {
          sectors.map((obj, idx) => 
            <MenuItem 
              {...cProps}
              key={obj.id}
              title={obj.name}
              url={generatePath(monitoringSectorPath, {id: obj.id})}
              icon="/media/svg/icons/General/Star.svg"
              />
          )
        }
      </>
  );
}

export default injectIntl(MonitoringSection)
