/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { isProduction } from "_metronic/_helpers";
import { 
  ComponentsSection, 
  PrincipalSection, 
  MonitoringSection, 
  ManagementSection,
  ReportSection
} from "./sections"

export function AsideMenuList({ layoutProps }) {
  const { user, sectors } = useSelector((state) => {
    return state.auth});

  return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <PrincipalSection 
            user={user}
            />
          <MonitoringSection 
            user={user}
            sectors={sectors}
            />
          <ReportSection 
            user={user}
            />
          <ManagementSection 
            user={user}
            />
          {
            !isProduction() && <ComponentsSection />
          }
        </ul>
      </>
  );
}
