import React from "react";
import { injectIntl } from "react-intl"
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import { convertDateTimeToDMY, convertWithFormat } from "_metronic/_helpers";

export interface PHistoryData {
  timestamp: number
  positionId: number
}
export interface PHistoryProps {
  intl: any
  data: Array<PHistoryData>
  width?: number
  height?: number
}
function DeviceUsageHeatMap(props: PHistoryProps) {
  const { intl, data, width, height } = props

  const [options] = React.useState<ApexOptions>({
    chart: {
      id: 'device-usage',
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    title: {
      text: intl.formatMessage({id: 'CHART.PACIENT_HISTORY.POSITION.TITLE'}),
      align: 'left'
    },
    noData: {
      text: intl.formatMessage({id: 'CHART.PACIENT_HISTORY.POSITION.NO_DATA'}),
    },
    markers: {
      size: 6,
      showNullDataPoints: true,
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
        formatter: (ts: number) => convertDateTimeToDMY(new Date(ts).getTime())
      },
      y: {
        title: {
          formatter: (seriesName: string) => ""
        },
        // formatter: (id: number) => intl.formatMessage({id: `POSITIONS.${positionTypeList[id]}`})
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (ts: string) => [
          convertWithFormat(new Date(ts).getTime(), "HH:mm:ss")
        ],
        datetimeUTC: true,
        trim: false
      },
      tickAmount: 15,
      tickPlacement: 'on',
    },  
    yaxis: {
      forceNiceScale: true,
      min: 0,
      // max: positionTypeList.length -1,
      tickAmount: 8,
      labels: {
        maxWidth: 100,
        minWidth: 50,
        style: {
          fontSize: '10px'
        }
      }
    }
  })

  const [dataSerie] = React.useState([])

  React.useEffect(() => {
    if (data.length === 0) return

  }, [data])

  return (
    <Chart 
      type="heatmap"
      options={options} 
      series={[
        {
          name: 'evolucao',
          data: dataSerie
        }
      ]} 
      width={width ?? '100%'} 
      height={height ?? '100%'} 
      />
  )
}
export default injectIntl((DeviceUsageHeatMap))
