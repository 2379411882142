import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  Form,
  Col,
  InputGroup,
  Spinner
} from "react-bootstrap";
import deburr from "lodash/deburr";
import {
  TextField,
  Paper,
  MenuItem
} from "@material-ui/core";
import Downshift from 'downshift';
import { get as _get } from "lodash"

function renderInput(inputProps) {
  const { InputProps, isInvalid, isValid, ref, ...other } = inputProps;
  const status = isInvalid ? "is-invalid" : (isValid ? "is-valid" : "")

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        className:`form-control ${status}`,
        ...InputProps,
      }}
      {...other}
    />
  );
}
function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = suggestion.id === selectedItem?.id

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};
function getSuggestions(suggestions, value, valueThreshold, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength < (valueThreshold ?? 0) && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        const keep = count < 5 && deburr(suggestion.label.trim()).toLowerCase().includes(inputValue)

        if (keep) 
          count += 1;

        return keep;
      });
}
export const FormAutoComplete = (args) => {
  const { intl, required, disabled, suggestions, inputValueThreshold, lg, titleId, placeholderId, valueRef, values, errors, touched, handleBlur, setFieldValue, isLoading } = args
  const touchedValue  = _get(touched, valueRef)
  const errorValue    = _get(errors, valueRef)
  const isInvalid     = (touched && errors) && Boolean(touchedValue && errorValue)
  const isValid       = (touched && errors) && Boolean(touchedValue && !errorValue)

  const emptyOptions = (suggestions ?? []).length === 0
  const firstOptionMessage = isLoading ? "LIST.LOADING" : (emptyOptions ? "LIST.EMPTY" : placeholderId)

  function updateFieldValue(value) {
    setFieldValue(valueRef, value)
  }
  function handleDownshiftChange(value) {
    updateFieldValue(value)
  }
  function handleInputChange(value) {
    if (value.length === 0)
      updateFieldValue("")
  }

  return (
    <>
      <Form.Group as={Col} xs={12} lg={lg ?? 4}>
        <Form.Label><FormattedMessage id={titleId} /></Form.Label>
        <InputGroup className={`${(isInvalid ?? false) ? "is-invalid" : ""} ${(isValid ?? false) ? "is-valid" : ""}`}>
          <Downshift 
            required={required ?? false}
            key={valueRef}
            id={valueRef}
            name={valueRef}
            selectedItem={suggestions?.filter((el) => el.id === _get(values, valueRef))[0] ?? null}
            onBlur={handleBlur}
            onInputValueChange={handleInputChange}
            onSelect={(item) => handleDownshiftChange(item.id) }
            itemToString={(item) => item?.label ?? ""}
            >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem
          }) => {
            const { onBlur, onFocus, ...inputProps } = getInputProps({
              placeholder: intl.formatMessage({ id:firstOptionMessage })
            });

            return (
              <div style={{flex: 1}}>
                {renderInput({
                  name: valueRef,
                  type:"text",
                  placeholder: intl.formatMessage({id:titleId}),
                  fullWidth: true,
                  isInvalid: isInvalid ?? false,
                  isValid: isValid ?? false,
                  disabled: (disabled ?? false) || emptyOptions,
                  InputLabelProps: getLabelProps({ shrink: true }),
                  InputProps: {
                    onBlur: handleBlur, onFocus, disableUnderline: true 
                  },
                  inputProps
                })}

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper square>
                      {getSuggestions(suggestions, inputValue, inputValueThreshold ?? 1).map(
                        (suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: {
                                id: suggestion.id,
                                label: suggestion.label
                              }
                            }),
                            highlightedIndex,
                            selectedItem
                          })
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            );
          }}
          </Downshift>
          {
            (isLoading) && 
              <InputGroup.Append>
                <Spinner animation="grow" variant="success" style={{alignSelf: 'center', marginLeft: 8}} />
              </InputGroup.Append>
          }
        </InputGroup>
        <Form.Control.Feedback type="invalid">{ errors && errorValue }</Form.Control.Feedback>
      </Form.Group>
    </>
  )
}
