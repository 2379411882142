import { IMonitoringMorseScale } from "app/models/monitoring"

export const MorseScales = {
  EMPTY     : "EMPTY",
  LOW       : "LOW",
  MEDIUM    : "MEDIUM",
  HIGH      : "HIGH"
}
export function morseScaleRangeStyles(_scalePoints: number | string) {
  let scalePoints = _scalePoints !== "" ? _scalePoints : 0

  switch(true) {
    case (scalePoints === 0): 
      return {
        variant: "info",
        color: "text-info",
        rangeTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.EMPTY",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.EMPTY.SHORT",
        category: "EMPTY",
        index: 0
      }
    case (scalePoints < 24): 
      return {
        variant: "primary",
        color: "text-primary",
        rangeTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.LOW",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.LOW.SHORT",
        category: "LOW",
        index: 1
      }
    case (scalePoints < 44): 
      return {
        variant: "warning",
        color: "text-warning",
        rangeTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.MEDIUM",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.MEDIUM.SHORT",
        category: "MEDIUM",
        index: 2
      }
    default: 
      return {
        variant: "danger",
        color: "text-danger",
        rangeTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.HIGH",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.MORSE_SCALE.RANGE.HIGH.SHORT",
        category: "HIGH",
        index: 3
      }
  }
}

export function calculateScaleScore(scale: IMonitoringMorseScale): number {
  const { singlePoints, detailed } = scale ?? {}

  if (singlePoints)
    return singlePoints
  else if (detailed)
    return Object.values(detailed).reduce((partial, total) => partial + total, 0)
  else
    return 0
}
