import { setSentryUser } from '_metronic/_helpers/SentryHelper'
import { fAuth, fGetFunction } from './firebase'

export function login(email, password) {
  return fAuth.signInWithEmailAndPassword(email, password)
}

export function logout() {
  setSentryUser(null)
  return fAuth.signOut()
}

export function getUserByToken() {
  return _getUserByToken()
    .catch((err) => _getUserByToken())
}

function _getUserByToken() {
  return fGetFunction(`users/me`)
}
