/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "_metronic/_helpers";
import { FormattedMessage } from "react-intl";

export function MenuItem(props) {
  const { intl, url, title, titleId, icon, display, parentTitleId } = props
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  if (display === undefined || !display) return null

  return (
      <>
        <li className={`menu-item ${getMenuItemActive(url, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={url}>
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl(icon)}/>
          </span>
          <span data-parent-title={parentTitleId ? intl.formatMessage({id: parentTitleId}) : ""} className="menu-text">{ title ? title : <FormattedMessage id={titleId} /> }</span>
          </NavLink>
        </li>
      </>
  );
}
