import { BaseModel } from "./base"
import { PositionTypes } from "./position"
import { IntlFormatters } from "react-intl"
import { prettyElaspedTime } from "_metronic/_helpers"
import { IMonitoring, IMonitoringPermanenceByPosition } from "./monitoring"

export const NotificationSeverity = {
  SUCCESS : "SUCCESS",
  INFO    : "INFO",
  WARNING : "WARNING",
  ERROR   : "ERROR",
  DANGER  : "DANGER"
} as const
export type NotificationSeverities = typeof NotificationSeverity[keyof typeof NotificationSeverity]

export const NotificationCategory = {
  BATTERY                 : "BATTERY",
  POSITION                : "POSITION",
  PRESSURE_INJURY         : "PRESSURE_INJURY",
  POSITIONS_UPDATED       : "POSITIONS_UPDATED",
  DEVICE_CHANGE           : "DEVICE_CHANGE",
  PRESSURE_INJURY_UPDATED : "PRESSURE_INJURY_UPDATED",
  MORSE_SCALE_UPDATED     : "MORSE_SCALE_UPDATED",
  BRADEN_SCALE_UPDATED    : "BRADEN_SCALE_UPDATED"
} as const
export type NotificationCategories = typeof NotificationCategory[keyof typeof NotificationCategory]

export interface INotificationPacient {
  id: string
  name: string
  location: string
}
export interface INotificationPressureInjuryControlPacient {
  monitoring: IMonitoring
  nextAlert: number,
  permanenceByPosition: Array<IMonitoringPermanenceByPosition>
}
export interface INotificationBatteryData {
  batteryCharge: number
}
export interface INotificationPositionData {
  position: PositionTypes
}
export interface INotificationPressureInjuryControlData {
  pacients: Array<INotificationPressureInjuryControlPacient>
}
export type INotificationDataTypes = INotificationBatteryData | INotificationPositionData | INotificationPressureInjuryControlData
export interface INotification {
  deviceId: string
  category: NotificationCategories
  severity: NotificationSeverities
  timestamp: number
  pacient: INotificationPacient
  data: INotificationDataTypes
}

export class Notification extends BaseModel implements INotification {
  readonly deviceId: string
  readonly category: NotificationCategories
  readonly severity: NotificationSeverities
  readonly timestamp: number
  readonly pacient: INotificationPacient
  readonly data: INotificationDataTypes

  constructor(args: INotification) {
    super()
    
    this.deviceId   = args.deviceId
    this.category   = args.category
    this.severity   = args.severity
    this.timestamp  = args.timestamp
    this.pacient    = args.pacient
    this.data       = args.data
  }

  getSeverityStyle(): string {
    return this.severity.toLowerCase()
  }

  getTranslatedTitle(intl: IntlFormatters): string {
    return intl.formatMessage({id: `NOTIFICATIONS.ALERTS.${this.category}.${this.severity}.TITLE`})
  }
  getTranslatedMessage(intl: IntlFormatters): string {
    const args: any = {
      who: this.pacient?.name ?? this.pacient?.location,
    }
  
    if (this.category === NotificationCategory.POSITION)
      args.position = intl.formatMessage({ id: `POSITIONS.${(this.data as INotificationPositionData).position}.SHORT`})
    else if (this.category === NotificationCategory.BATTERY)
      args.charge = (this.data as INotificationBatteryData).batteryCharge
  
    return intl.formatMessage({ id:`NOTIFICATIONS.ALERTS.${this.category}.${this.severity}.MESSAGE` }, args)
  }
  getPrettyElapsedTime(): string {
    return prettyElaspedTime(this.timestamp)
  }

  isCategoryEnabled(): boolean {
    return (this.category in NotificationCategory)
  }

  isCategory(category: NotificationCategories): boolean {
    return this.category === category
  }
}
