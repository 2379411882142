/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl"
import { 
  Notification,
} from "app/models/notification"

const severities = new Map([
  ['danger', {
    name: "danger",
    variant: "error",
    icon: "flaticon2-warning",
    color: "danger",
    textColor: "text-danger"
  }],
  ['warning', {
    name: "warning",
    variant: "warning",
    icon: "flaticon2-warning",
    color: "warning",
    textColor: "text-warning"
  }],
  ['success', {
    name: "success",
    variant: "success",
    icon: "flaticon2-check-mark",
    color: "success",
    textColor: "text-success"
  }]
])

export interface NotificationItemProps {
  intl: any
  notification: Notification
  onClick: (notification: Notification) => {}
}
export const NotificationItem = (props: NotificationItemProps) => {
  const { intl, notification, onClick } = props
  const { category, severity, timestamp } = notification
  const { icon, color, textColor } = severities.get(severity.toLowerCase()) ?? {}
  
  return (
    <div onClick={() => onClick(notification)} key={`${category}.${timestamp}`} className="d-flex align-items-center mb-4 cursor-pointer bg-hover-primary py-1 px-4">
      <div className={`symbol symbol-40 symbol-light-${color} mr-5`}>
        <span className="symbol-label">
          <div className="navi-icon">
            <i className={`${icon} icon-lg ${textColor}`} />
          </div>
        </span>
      </div>
      <div className="d-flex flex-column font-weight-bold text-hover-white" style={{flexGrow: 1}}>
        <span className={`${textColor} mb-1 font-size-lg`}>
          { notification.getTranslatedTitle(intl) }
        </span>
        <span className="text-black">
          { notification.getTranslatedMessage(intl) }
        </span>
        <span className="text-muted text-right font-size-sm">
          { notification.getPrettyElapsedTime() }
        </span>
      </div>
    </div>
  )
}

export const NotificationEmptyItem = () => {
  return (
    <div className="d-flex align-items-center mb-6">
      <div className={`symbol symbol-40 symbol-light-success mr-5`}>
        <span className="symbol-label">
          <div className="navi-icon">
            <i className={`flaticon2-check-mark icon-lg text-success`} />
          </div>
        </span>
      </div>
      <div className="d-flex flex-column font-weight-bold">
        <span className="text-black">
          <FormattedMessage id="NOTIFICATIONS.EMPTY_LIST"/>
        </span>
      </div>
    </div>
  )
}
