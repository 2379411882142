import { BaseModel } from "./base"

export interface IPacient {
  id: string
  name: string
  medicalRecord: string
  location: string
  birthday: string
  sectorId?: string | null
  deviceId?: string | null
  monitoringId?: string | null
}

export interface IPacientMonitoring {
  sectorId?: string | null
  deviceId?: string | null
  monitoringId?: string | null
}

export class Pacient extends BaseModel implements IPacient, IPacientMonitoring {
  readonly id: string
  readonly name: string
  readonly medicalRecord: string
  readonly location: string
  readonly birthday: string
  readonly deviceId?: string | null
  readonly sectorId?: string | null
  readonly monitoringId?: string | null

  constructor(args: IPacient) {
    super()

    this.id                = args.id
    this.name              = args.name ?? ""
    this.medicalRecord     = args.medicalRecord
    this.location          = args.location
    this.birthday          = args.birthday
    this.sectorId          = args.sectorId ?? null
    this.deviceId          = args.deviceId ?? null
    this.monitoringId      = args.monitoringId ?? null
  }

  getFormattedName(): string | undefined {
    const _name          = (this.name ?? "")
    const _medicalRecord = (this.medicalRecord ? `${this.medicalRecord} - ` : undefined) ?? ""
    const formattedName  = _medicalRecord + _name
    
    return (formattedName.length > 0) ? formattedName : undefined
  }
}
