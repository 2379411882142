/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl"
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { getEpoch, toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { NotificationsProvider } from "app/providers"
import { NotificationItem, NotificationEmptyItem } from "./dropdown.item"
import { NotificationCategory, NotificationSeverity } from "app/models/notification"
import useSound from 'use-sound';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import dangerSfx from 'app/assets/sounds/danger.mp3';
import pressureInjurySfx from 'app/assets/sounds/pressure-injury-1.mp3';
import PressureInjuryDialogList from "./PressureInjuryDialogList";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
}
const handleToastSeverity = (severity) => {
  switch(severity) {
    case NotificationSeverity.INFO:
      return "info"
    case NotificationSeverity.WARNING:
      return "warning"
    case NotificationSeverity.ERROR:
    case NotificationSeverity.DANGER:
    default:
      return "error"
  }
}

const UserNotificationsDropdown = (props) => {
  const { intl } = props

  const { allNotifications: notifications, arrivedNotifications, isLoaded } = React.useContext(NotificationsProvider.Context);
  const [notificationsReadCount, setNotificationsReadCount] = React.useState(0)
  const [localNotificationItems, setLocalNotificationItems] = React.useState([])

  const [isPlaying, setIsPlaying] = React.useState(false)

  const [selectedPressureInjury, setSelectedPressureInjury] = React.useState(null)

  const notificationsLength   = notifications?.length ?? 0
  const newNotificationsDiff  = notificationsLength - notificationsReadCount
  const newNotifications      = (newNotificationsDiff < 0) ? 0 : newNotificationsDiff
  const hasNewNotifications   = (newNotifications > 0)
  const emptyNotifications    = notificationsLength === 0

  const [key, setKey] = useState("Alerts")
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg")

  const playerStateController = { onplay:() => setIsPlaying(true), onend:() => setIsPlaying(false) }
  const [playDangerAudio,         { stop: stopDangerAudio,        }] = useSound(dangerSfx,         {interrupt: true, ...playerStateController})
  const [playPressureInjuryAudio, { stop: stopPressureInjuryAudio }] = useSound(pressureInjurySfx, {interrupt: true, ...playerStateController})

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  toastr.options = {
    "closeButton": true,
    "onHidden": () => { stopAudios() },
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "36000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }

  React.useEffect(() => {
    if (!isLoaded()) return

    console.debug('latest notifications loaded')
    prepareNotificationsList()
  }, [notifications])
  React.useEffect(() => {
    handleToasts(arrivedNotifications)
  }, [arrivedNotifications])

  async function playAudio(category) {
    stopAudios()
    switch(category) {
      case NotificationCategory.PRESSURE_INJURY:
        playPressureInjuryAudio()
        break;
      case NotificationCategory.BATTERY:
      case NotificationCategory.POSITION:
      default:
        playDangerAudio()
        break
    }
  }
  async function stopAudios() {
    stopDangerAudio()
    stopPressureInjuryAudio()
  }

  function prepareNotificationsList() {
    setLocalNotificationItems(
      notifications.map((el, idx) => {
        if (!el.isCategoryEnabled()) return null

        return (
          <NotificationItem
            key={`${el.category}.${el.timestamp}`}
            intl={intl}
            notification={el}
            onClick={(notification) => onNotificationClicked(notification) }
            />
        )
      })
    )
  }
  async function handleToasts(notifications) {
    notifications.forEach((el, idx) => {
      if (!el.isCategoryEnabled()) return

      playAudio(el.category)

      toastr[handleToastSeverity(el.severity)](
        el.getTranslatedMessage(intl),
        el.getTranslatedTitle(intl),
        {
          onclick: () => onNotificationClicked(el)
        }
      )
    })
  }

  function onNotificationClicked(notification) {
    onToggleDropdown()

    if (notification.isCategory(NotificationCategory.PRESSURE_INJURY))
      setSelectedPressureInjury(notification)
  }

  function onToggleDropdown(isOpen) {
    // update notifications read count when dropdown closes
    if (!isOpen && hasNewNotifications)
      setNotificationsReadCount(notificationsLength)

    if (isOpen)
      prepareNotificationsList()
  }

  return (
    <>
      {
        (selectedPressureInjury) && (
          <PressureInjuryDialogList
            notification={selectedPressureInjury}
            onClose={() => setSelectedPressureInjury(null)}
            />
        )
      }
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <div className="navi-icon">
                <i className={`flaticon2-bell-5 icon-lg text-primary`} />
              </div>
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown 
          drop="down" 
          alignRight 
          onToggle={onToggleDropdown}>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  <FormattedMessage id="NOTIFICATIONS.TITLE" />
                </Tooltip>
              }
            >
              <div
                id="kt_quick_notifications_toggle"
                className={`btn btn-icon btn-clean btn-lg mr-1 pulse pulse-${hasNewNotifications ? 'danger' : 'primary'}`} 
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <div className="navi-icon">
                    <i className={`flaticon2-bell-5 icon-lg text-primary`} />
                  </div>
                </span>
                {
                  (hasNewNotifications) && (
                    <>
                      <span className="pulse-ring"></span>
                      <span className="pulse-ring" />
                    </>
                  )
                }
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white"><FormattedMessage id="NOTIFICATIONS.TITLE" /></span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                  <FormattedMessage id="NOTIFICATIONS.UNREAD" values={{count: newNotifications}} />
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="Alerts"
                        className={`nav-link show ${
                          key === "Alerts" ? "active" : ""
                        }`}
                      >
                        <FormattedMessage id="NOTIFICATIONS.ALERTS" />
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pt-4"
                        style={{ flexDirection: 'column', maxHeight: "450px" }}
                      >
                        { (emptyNotifications) ? <NotificationEmptyItem /> : localNotificationItems }
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export default injectIntl(UserNotificationsDropdown)
