import React from "react";
import { useSelector } from "react-redux";

export function ClientHeader() {
  const { user } = useSelector((state) => state.auth);

  return(
    <>
      <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
        <div className="text-dark font-weight-bold" style={{display:'flex', height:'100%', alignItems:'center'}}>
          <h6 style={{margin:0}}>{user.client}</h6>
        </div>
      </div>
    </>
  )
}
