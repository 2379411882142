import React from "react";
import { injectIntl } from "react-intl"
import { BaseDonut } from ".";
import { getEpoch } from "_metronic/_helpers";

export interface MorseMonitoringsDonutData {
  active: number
  total: number
}
export interface MorseMonitoringsDonutProps {
  intl: any
  data?: MorseMonitoringsDonutData
  width?: number
  height?: number
}
function MorseMonitoringsDonut(props: MorseMonitoringsDonutProps) {
  const { intl, data, width, height } = props
  const [newSignal, setNewSignal] = React.useState<number>(0)
  
  const [labels] = React.useState<Array<string>>([
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.EMPTY" }),
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.LOW" }),
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.MEDIUM" }),
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.HIGH" }),
  ])
  const [colors] = React.useState<Array<string>>([
    '#b3b3b3d9',
    '#19f6fed9',
    '#effe19d9',
    '#fe1919d9'
  ])

  const onDataSerie = () => {
    // if (!data) return null
    return [
      10, 30, 40, 60
    ]
  }

  React.useEffect(() => {
    setNewSignal(getEpoch())
  }, [data])

  return (
    <BaseDonut
      width={width ?? '100%'} 
      height={height ?? '100%'}
      id='morse-monitorings'
      title={intl.formatMessage({id: "REPORTS.MORSE.RANGE.TITLE"})}
      colors={colors}
      labels={labels}
      onDataSerie={onDataSerie}
      newDataSignal={newSignal}
      />
  )
}
export default injectIntl((MorseMonitoringsDonut))
