import React from "react";
import { injectIntl } from "react-intl"
import { PositionTypes } from "app/models/position";
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"

export interface IPermanenceData {
  duration: number
  positionId: PositionTypes
}
export interface IPermanenceProps {
  intl: any
  data: Array<IPermanenceData>
  width?: number
  height?: number | string
  displayTitle?: boolean
  yOffset?: number
}
function PermanenceByPositionBarChart(props: IPermanenceProps) {
  const { intl, data, width, height, displayTitle, yOffset } = props

  const [preparedData] = React.useState<Array<IPermanenceData>>(data?.sort((a, b) => (a.duration < b.duration) ? 1 : -1).slice(0, 4) ?? [])
  const [positionTypeList] = React.useState<Array<string>>(preparedData.map((v: IPermanenceData) => v.positionId))
  const [options] = React.useState<ApexOptions>({
    chart: {
      id: 'permanence-by-position',
      toolbar: {
        show: false,
        tools: {
          download: false
        }
      },
      offsetY: yOffset ?? 0
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '30%',
        dataLabels: {
          position: 'bottom'
        },
        colors: {
          backgroundBarColors: [
            'F0F', '0F0'
          ]
        }
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      offsetX: 0,
      offsetY: 0,
      formatter:(duration: number) => intl.formatMessage({id: 'CHART.PACIENT_PERMANENCE.DATA_LABEL'}, {duration: duration})
    },
    title: {
      text: displayTitle && intl.formatMessage({id: 'CHART.PACIENT_PERMANENCE.POSITION.TITLE'}),
      align: 'left'
    },
    noData: {
      text: intl.formatMessage({id: 'CHART.PACIENT_PERMANENCE.POSITION.NO_DATA'}),
    },
    tooltip: {
      enabled: true,
      followCursor: false,
      x: {
        show: !!0,
        format: '',
        formatter: (ts: number) => ``
      },
      y: {
        title: {
          formatter: (seriesName: string) => ""
        },
        formatter: (_: any, opts?: any) => {
          const posId     = positionTypeList[opts?.dataPointIndex]
          const duration  = opts?.end
          const translation = intl.formatMessage({id: `POSITIONS.${posId}.SHORT`})
          
          return intl.formatMessage(
            { id: "CHART.PACIENT_PERMANENCE.TOTAL_DURATION.Y.TOOLTIP"},
            { duration: duration, position: translation }
          )
        }
      }
    }
  })
  const [dataSerie] = React.useState<Array<any>>(preparedData.map((v: IPermanenceData, i: number) => { return {x: v.positionId, y: [0, v.duration]} }))

  return (
    <Chart
      type="rangeBar"
      options={options} 
      series={[
        {
          name: 'permanencia',
          data: dataSerie
        }
      ]} 
      width={width ?? '100%'} 
      height={height ?? '100%'} 
      />
  )
}
export default injectIntl((PermanenceByPositionBarChart))
