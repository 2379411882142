import React from "react";
import { injectIntl } from "react-intl"
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import { toAbsoluteUrl } from "_metronic/_helpers";

export interface PIllustratedPositionDurationChart {
  DDH: number
  DV:  number
  DLE: number
  DLD: number
  SED: number
}

function convertMinToHumanReadable(totalMins: number): string {
  if (isNaN(totalMins)) return ""

  let hr        = Math.floor(totalMins / 60)
  let mins      = Math.floor(totalMins % 60)

  return `${(hr > 0) ? `${hr}h` : ""} ${mins > 0 ? `${mins}min` : ""}`
}

export interface PIllustratedPositionDurationChartProps {
  intl: any
  data: PIllustratedPositionDurationChart
  width?: number
  height?: number
}
function IllustratedPositionDurationChart(props: PIllustratedPositionDurationChartProps) {
  const { data, width, height } = props

  const [options] = React.useState<ApexOptions>({
    chart: {
      id: 'apex',
    },
    title: {
      text: "Permanência por Decúbito",
      align: 'left',
    },
    grid: {
      padding: {
        top: 20,
        left: 10,
        right: 20,
        bottom: 50
      }
    },
    noData: {
      style: {
        color: "red"
      },
      text: "Nothing"
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 1,
        }
      }
    },
    stroke:{
      colors:['#0000000']
     },
    labels: ['Dorsal', 'Ventral', 'Lateral Esquerdo', 'Lateral Direito', 'Sedestação'],
    dataLabels: {
      enabled: true,
      textAnchor: 'end',
      formatter: (value, { seriesIndex, w }) => convertMinToHumanReadable(w.config.series[seriesIndex])
    },
    tooltip: { 
      enabled: true,
      y: {
        formatter: (value) => convertMinToHumanReadable(value)
      }
    },
    legend: {
      show: false
    },
    yaxis: {
      show: false
    }
  })

  const [dataSerie, setDataSerie] = React.useState<Array<number>>([0,0,0,0,0])
  React.useEffect(() => {
    setDataSerie([data.DDH,data.DV,data.DLE,data.DLD,data.SED])
  }, [data])

  return (
    <div style={{backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundImage: `url(${toAbsoluteUrl("/media/misc/illustrated-positions.svg")})`}}>
    <Chart
      type="polarArea"
      options={options} 
      series={dataSerie} 
      width={width ?? '100%'} 
      height={height ?? '100%'} 
      />
    </div>
  )
}
export default injectIntl((IllustratedPositionDurationChart))
