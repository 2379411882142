import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Modal
} from "react-bootstrap";

export interface ModalInfoProps {
  size?       : "sm" | "lg" | "xl",
  titleId     : string,
  bodyTextId? : string,
  showSignal  : number
}
export const ModalInfo = (props: ModalInfoProps) => {
  const { size, titleId, bodyTextId, showSignal } = props

  const [display, setDisplay] = useState<boolean>(false)

  const handleClose = () => setDisplay(false)

  useEffect(() => setDisplay(showSignal > 0), [showSignal])

  return (
    <Modal size={size ?? "lg"} show={display} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><FormattedMessage id={titleId} /></Modal.Title>
      </Modal.Header>
      <Modal.Body><FormattedMessage id={bodyTextId} /></Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          <FormattedMessage id="CLOSE" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
