import { PositionType } from "app/models/position"

export const POS_LIST = [
  PositionType.DDH,
  PositionType.DV,
  PositionType.DLE,
  PositionType.DLD,
  PositionType.DDE,
  PositionType.SED
]

export function getPositionTranslation(id) {
  return {
    intlId: `POSITIONS.${id}`,
    intlShortId: `POSITIONS.${id}.SHORT`,
  }
}

export function deviceDecoder(payload) {
  if (payload === undefined || payload === null) return { }

  // sensors status are received as an integer  => 55
  // we convert it into a 32bit                 => 00000000000000000000000000110111
  const bitSize       = 32
  const sensorsStatus = (payload.sState >>> 0).toString(2).padStart(bitSize, "0")
  function getSensorStatusAt(index) {
    return Boolean(sensorsStatus.charAt((bitSize - 1) - index))
  }

  return {
    raw             : payload,
    x               : parseInt(payload.x),
    y               : parseInt(payload.y),
    z               : parseInt(payload.z),
    version         : payload.v,
    timestamp       : payload.ts,
    spO2            : parseInt(payload.spo),
    wakeReason      : payload.wkReason,
    moduleId        : payload.moduleId,
    bootCount       : payload.bootCount,
    heartBeat       : parseInt(payload.hr),
    internalTemp    : parseFloat(payload.it).toFixed(1) ?? "-1",
    pacientTemp     : parseFloat(payload.et).toFixed(1) ?? "-1",
    batteryVoltage  : payload.bV,
    batteryCharge   : payload.bC,
    fullDateTime    : payload.fDateTime,
    
    isCharging      : payload.bCh,

    upside          : payload.upside,
    positionId      : payload.positionId,
    linkedPositions : payload.linkedPositions,
    radX            : payload.radX,
    radY            : payload.radY,

    arrivedAt       : payload.arrivedAt,
    
    sensorsEnabled  : {
      // mapped from right to left
      //[ 7][   6][ 5][ 4][ 3][2][1][0]
      //[hr][spo2][et][it][bC][z][y][x]
      heartBeat       : getSensorStatusAt(7),
      spO2            : getSensorStatusAt(6),
      pacientTemp     : getSensorStatusAt(5),
      internalTemp    : getSensorStatusAt(4),
      batteryVoltage  : getSensorStatusAt(3)
    }
  }
}
