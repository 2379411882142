import React from "react";
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"

export interface BaseDonutProps {
  width?: number | string
  height?: number | string
  id: string
  title: string
  colors: Array<string>
  labels: Array<string>
  onDataSerie: () => Array<number> | null
  newDataSignal: number
}
function BaseDonut(props: BaseDonutProps) {
  const { width, height, id, title, colors, labels, onDataSerie, newDataSignal } = props

  const [options] = React.useState<ApexOptions>({
    chart: {
      id: id
    },
    title: {
      text: title,
      align: 'left',
      style: {
        color: "white"
      }
    },
    noData: {
      style: {
        color: "red"
      },
      text: "Nothing"
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true,
              color: "white"
            }
          }
        }
      }
    },
    stroke:{
      colors:['#0000000']
     },
    colors: colors,
    labels: labels,
    dataLabels: {
      formatter: (value, { seriesIndex, w }) => w.config.series[seriesIndex],
    },
    tooltip: { 
      enabled: false
    },
    legend: {
      horizontalAlign: 'left',
      labels: {
        colors: "white"
      }
    }
  })

  const [dataSerie, setDataSerie] = React.useState<Array<number>>([0,0])
  React.useEffect(() => {
    const data = onDataSerie()
    if (!data) return

    setDataSerie(data)
  }, [newDataSignal, onDataSerie])

  return (
    <Chart
      type="donut"
      options={options} 
      series={dataSerie} 
      width={width ?? '100%'} 
      height={height ?? '100%'} 
      />
  )
}
export default (BaseDonut)
