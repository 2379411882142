import React from "react";
import {
  Col,
  Form,
  InputGroup,
  Spinner
} from "react-bootstrap";
import { get as _get } from "lodash"

export const FormDropDown = (props) => {
  const { intl, required, disabled, firstOptionValue, disabledFeedback, xs, lg, className, plainTitle, titleId, valueRef, overrideValue, values, options, errors, touched, handleChange, handleBlur, isLoading } = props
  const touchedValue  = _get(touched, valueRef)
  const errorValue    = _get(errors, valueRef)
  const isInvalid     = ((touched && errors) && Boolean(touchedValue && errorValue)) ?? false
  const isValid       = ((touched && errors) && Boolean(touchedValue && !errorValue)) ?? false

  const feedbackEnabled = !disabledFeedback ?? true

  const emptyOptions = (options ?? []).length === 0
  const firstOptionMessage = intl.formatMessage({id: isLoading ? "LIST.LOADING" : emptyOptions ? "LIST.EMPTY" : "LIST.SELECT" })

  const ThisForm = () => <>
    <Form.Control
      style={{backgroundPosition:'right calc(1em + 0.325rem) center'}}
      as="select"
      required={required ?? false}
      disabled={(disabled ?? false) || emptyOptions}
      key={valueRef}
      id={valueRef}
      name={valueRef}
      placeholder={(plainTitle) ? plainTitle : intl.formatMessage({id: titleId})}
      value={overrideValue ?? _get(values, valueRef) ?? ""}
      isInvalid={isInvalid && feedbackEnabled}
      isValid={isValid && feedbackEnabled}
      onChange={handleChange}
      onBlur={handleBlur}
      className={className}
    >
      <option key={"#sel"} value={firstOptionValue ?? ""}>{ firstOptionMessage }</option>
      {
        options?.map((el) =>
          <option key={el.id} value={el.id}>{ el.label }</option>  
        )
      }
    </Form.Control>
  </>

  return (
    <Form.Group as={Col} xs={xs ?? 12} lg={lg ?? 4}>
      <Form.Label>
        {
          (plainTitle) ? plainTitle : intl.formatMessage({id: titleId})
        }
      </Form.Label>
        {
          (isLoading) && 
          <InputGroup>
            <ThisForm />
            <InputGroup.Append>
              <Spinner animation="grow" variant="success" style={{alignSelf: 'center', marginLeft: 8}} />
            </InputGroup.Append>
          </InputGroup>
        }
        {
          (!isLoading) &&
            <ThisForm />
        }
      {
        (feedbackEnabled) && <Form.Control.Feedback type="invalid">{ errors && errorValue}</Form.Control.Feedback>
      }
    </Form.Group>
  )
}
