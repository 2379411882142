import React from "react";
import { injectIntl } from "react-intl"
import { PositionType, PositionTypes } from "app/models/position";
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import { convertDateTimeToDMY, convertWithFormat } from "_metronic/_helpers";

export interface PHistoryData {
  timestamp: number
  positionId: PositionTypes
}
export interface PHistoryProps {
  intl: any
  data: Array<PHistoryData>
  width?: number
  height?: number
}
function PositionHistoryChart(props: PHistoryProps) {
  const { intl, data, width, height } = props

  const [positionTypeList] = React.useState<Array<string>>([
    PositionType.DV,
    PositionType.DLE_DV,
    PositionType.DLE,
    PositionType.DLD_DV,
    PositionType.DDH,
    PositionType.DDH_DLE,
    PositionType.DLD,
    PositionType.DDH_DLD,
    PositionType.DDE,
    PositionType.DDH_DDE,
    PositionType.SED,
    PositionType.DDE_SED,
    PositionType.FALL_RAISED,
    PositionType.INV,
    PositionType.UPSIDE_DOWN,
    PositionType.UNK
  ])
  const [positionTypesMappingTextToId] = React.useState<Map<string, number>>(positionTypeList.reduce((p: Map<string, number>, v: string, i: number) => { p.set(v, i); return p }, new Map() as Map<string, number>))

  const [options] = React.useState<ApexOptions>({
    chart: {
      id: 'position-history',
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    title: {
      text: intl.formatMessage({id: 'CHART.PACIENT_HISTORY.POSITION.TITLE'}),
      align: 'left'
    },
    noData: {
      text: intl.formatMessage({id: 'CHART.PACIENT_HISTORY.POSITION.NO_DATA'}),
    },
    markers: {
      size: 6,
      showNullDataPoints: true,
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
        formatter: (ts: number) => convertDateTimeToDMY(new Date(ts).getTime())
      },
      y: {
        title: {
          formatter: (seriesName: string) => ""
        },
        formatter: (id: number) => intl.formatMessage({id: `POSITIONS.${positionTypeList[id]}`})
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (ts: string) => [
          convertWithFormat(new Date(ts).getTime(), "HH:mm:ss")
        ],
        datetimeUTC: true,
        trim: false
      },
      tickAmount: 15,
      tickPlacement: 'on',
    },  
    yaxis: {
      forceNiceScale: true,
      min: 0,
      max: positionTypeList.length -1,
      tickAmount: 8,
      labels: {
        formatter: (val: number, opts?: any) => {
          const id = positionTypeList[val] ?? PositionType.UNK

          // has NOT two lines label
          if (!!intl.messages[`POSITIONS.${id}.CHART`])
            return intl.formatMessage({id: `POSITIONS.${id}.CHART`})
          else 
            return [
              intl.formatMessage({id: `POSITIONS.${id}.CHART1`}),
              intl.formatMessage({id: `POSITIONS.${id}.CHART2`})
            ]  
        },
        maxWidth: 100,
        minWidth: 50,
        style: {
          fontSize: '10px'
        }
      }
    }
  })
  const [dataSerie, setDataSerie] = React.useState([])

  React.useEffect(() => {
    if (data.length === 0) return
    
    const sanitizedDataSerie = data.reduce((dataArray: any, currentValue: PHistoryData, index: number) => {
      const pId = currentValue.positionId
      const nId = data[index+1]?.positionId

      // if next record has the same position id and array is not empty
      // skip insertion
      if (pId === nId && dataArray.length > 0) return dataArray

      dataArray.push([currentValue.timestamp, positionTypesMappingTextToId.get(pId)])
      return dataArray
    }, [])
    setDataSerie(sanitizedDataSerie)
  }, [data, positionTypesMappingTextToId])

  return (
    <Chart 
      type="line"
      options={options} 
      series={[
        {
          name: 'evolucao',
          data: dataSerie
        }
      ]} 
      width={width ?? '100%'} 
      height={height ?? '100%'} 
      />
  )
}
export default injectIntl((PositionHistoryChart))
