import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ErrorPage1 } from "./ErrorPage1";
import { ErrorPage2 } from "./ErrorPage2";
import { ErrorPage5 } from "./ErrorPage5";
import { NotFoundPage } from "./NotFoundPage";

import {
  notFoundPath
} from "api/endpoints"

export default function ErrorsPage() {
  return (
    <Switch>
      <Redirect from="/error" exact={true} to="/error/error-v1" />
      <Route path="/error/error-v1" component={ErrorPage1} />
      <Route path="/error/error-v2" component={ErrorPage2} />
      <Route path="/error/error-v5" component={ErrorPage5} />
      <Route path={notFoundPath}    component={NotFoundPage} />
    </Switch>
  );
}
