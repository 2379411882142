import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-6.scss";
import { Button } from "@material-ui/core";

export function NotFoundPage() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg-notfound.jpeg")})`,
        }}
      >
        <div className="d-flex flex-column flex-row-fluid text-center">
          <h1
            className="error-title font-weight-boldest text-white mb-12 mt-2"
          >
            Oops...
          </h1>
          <p className="display-4 font-weight-bold text-white">
            Você não possui acesso ou essa funcionalidade não está disponível.
            <br />
            <Button href="/" variant="contained" size="large" className="mt-10">
              Página Principal
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
}
