import { isProduction } from "_metronic/_helpers/CommonHelpers"
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
import "firebase/performance";
import { getTenantFirebaseConfigKey } from "tenants/tenant"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = getTenantFirebaseConfigKey()

// Get references to Cloud Firestore and the Authentication service and export them.
firebase.initializeApp(firebaseConfig);
export const fAuth        = firebase.auth();
export const fDatabase    = firebase.database();
export const fFirestore   = firebase.firestore();
export const fFunctions   = firebase.app().functions('southamerica-east1');
export const fPerformance = firebase.performance();

if (!isProduction()) {
  // fDatabase.useEmulator("localhost", 9000)
  fFunctions.useEmulator("localhost", 5001);
}

export function fCallable(path, data = {}) {
  return fFunctions.httpsCallable(path)(data)
    .then(({data}) => { return data })
}

export function fGetFunction(path, data = {}) {
  return fFunctions.httpsCallable(`api/${path}`)(data)
    .then(({data}) => { return data })
}

export function fGetLongFunction(path, data = {}) {
  return fFunctions.httpsCallable(`api_long/${path}`)(data)
    .then(({data}) => { return data })
}

export function fPostFunction(path, data) {
  return fFunctions.httpsCallable(`api/${path}`)(data)
    .then(({data}) => { return data })
}
